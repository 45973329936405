import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import SwiperCore, { Autoplay, Controller, Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay, Controller, Lazy, Navigation])

export interface Props {
  alignment: string
  cta?: ButtonProps[]
  description?: string
  images?: ImageProps[]
  index?: number
  label?: string
  title?: string
}

export const ZigZag = memo(function ZigZag({
  alignment,
  cta,
  description,
  images,
  index = 0,
  label,
  title,
}: Props) {
  return (
    <Container className={`zz-${alignment}`} row>
      <Slider>
        {images ? (
          <>
            <Swiper
              allowTouchMove={false}
              centeredSlides={true}
              draggable={false}
              lazy={{ loadPrevNext: true }}
              loop
              navigation={{
                nextEl: `.zig-zag-next-${index}`,
                prevEl: `.zig-zag-prev-${index}`,
              }}
              preloadImages={false}
              spaceBetween={2}
              speed={1000}
              watchSlidesProgress
              onProgress={(swiper) => {
                for (let e = 0; e < swiper.slides.length; e++) {
                  const parent = swiper.slides[e] as HTMLElement
                  const slide = swiper.slides[e].children[0] as HTMLElement
                  // @ts-ignore
                  const t = parent.progress * (swiper.width * 0.5)

                  slide.style.transform = 'translate3d(' + t + 'px, 0, 0)'
                }
              }}
              onTouchStart={(swiper) => {
                for (let e = 0; e < swiper.slides.length; e++) {
                  const slide = swiper.slides[e].children[0] as HTMLElement

                  slide.style.transition = e + 'ms'
                }
              }}
              onSetTransition={(swiper, transition) => {
                for (let e = 0; e < swiper.slides.length; e++) {
                  const parent = swiper.slides[e] as HTMLElement
                  const slide = parent.children[0] as HTMLElement

                  parent.style.transition = transition + 'ms'
                  slide.style.transition = transition + 'ms'
                }
              }}
            >
              {images.map((item, index) => (
                <SwiperSlide key={index}>
                  <SlideInner className="slide-inner">
                    <Image
                      className="swiper-lazy"
                      src={item.src}
                      srcSet={item.srcSet}
                      alt={item.alt}
                      width={item.width}
                      height={item.height}
                    />
                  </SlideInner>
                </SwiperSlide>
              ))}
            </Swiper>
            {images.length > 1 ? (
              <SliderControls className="zig-zag-slider-controls" row>
                <Arrow dial={5} className={`zig-zag-prev-${index}`}>
                  <Icon.ChevronLeft />
                </Arrow>
                <Arrow dial={5} className={`zig-zag-next-${index}`}>
                  <Icon.ChevronRight />
                </Arrow>
              </SliderControls>
            ) : null}
          </>
        ) : null}
      </Slider>
      <TextWrap className="zig-zag-text-wrap">
        {label ? <Label>{label}</Label> : null}
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {cta
          ? cta.map((item, index) => <Button key={index} {...item} />)
          : null}
      </TextWrap>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  &.zz-right {
    .swiper-container {
      padding-right: 19.6875rem;
      padding-left: 0;
      &:before {
        right: 0;
        left: auto;
      }
    }
    .zig-zag-text-wrap {
      margin-right: 6rem;
      margin-left: auto;
    }
    .zig-zag-slider-controls {
      right: 0;
      left: 6rem;
    }
  }

  @media (max-width: 1199px) {
    &.zz-right {
      .swiper-container {
        padding-right: 12.5rem;
        &:before {
          width: 12.5rem;
        }
      }
      .zig-zag-text-wrap {
        margin-right: 1.5rem;
      }
      .zig-zag-slider-controls {
        left: 1.5rem;
      }
    }
  }

  @media (max-width: 767px) {
    display: block;
    &.zz-right {
      .swiper-container {
        padding-right: 0;
      }
      .zig-zag-text-wrap {
        margin-right: 0;
      }
      .zig-zag-slider-controls {
        right: 0;
        left: auto;
      }
    }
  }
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  .swiper-container {
    height: 100%;
    padding-left: 19.6875rem;
    &:before {
      content: '';
      width: 19.6875rem;
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralLight4};
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    .swiper-slide {
      height: 100%;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    .swiper-container {
      padding-left: 14rem;
      &:before {
        width: 14rem;
      }
    }
  }

  @media (max-width: 767px) {
    position: relative;
    padding-bottom: 66%;
    .swiper-container {
      width: 100%;
      height: 100%;
      padding-left: 0;
      position: absolute;
      top: 0;
      left: 0;
      &:before {
        display: none;
      }
    }
  }
`

const SlideInner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:last-of-type {
    transition: all 0.8s ease;
  }
`

const SliderControls = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 6rem;
  z-index: 2;

  @media (max-width: 1199px) {
    right: 1.5rem;
  }

  @media (max-width: 767px) {
    right: 0;
  }
`

const Arrow = styled(FlexBox)`
  width: 3.25rem;
  height: 3.25rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
  }
  svg {
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
`

const TextWrap = styled.div`
  max-width: 27.625rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  margin: 6rem auto 6rem 6rem;
  padding: 4.875rem 4.5rem 0;
  position: relative;
  z-index: 2;
  text-align: center;

  @media (max-width: 1199px) {
    max-width: 25rem;
    margin: 3.75rem auto 3.75rem 1.5rem;
    padding: 3.75rem 3rem 0;
  }

  @media (max-width: 767px) {
    max-width: none;
    margin: 0;
    padding: 2.25rem 1.5rem 0;
    text-align: left;

    > a {
      margin-top: 2.25rem;
    }
  }
`

const Label = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.9375rem;
  letter-spacing: 0.0938rem;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-bottom: 4.5rem;

  @media (max-width: 1199px) {
    margin-bottom: 3.75rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`
