import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import React, { memo } from 'react'

import { Props as ZigZagProps, ZigZag } from './ZigZag'

export interface Props {
  stripes: ZigZagProps[]
}

export const ZigZagList = memo(function ZigZagList({ stripes }: Props) {
  if (stripes.length < 1) {
    return null
  }

  return (
    <Container>
      {stripes.map((item, index) => (
        <FadeInOutAnimation key={index}>
          <ZigZag {...item} index={index} />
        </FadeInOutAnimation>
      ))}
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  > div {
    margin-top: 0.125rem;
    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 1199px) {
    margin: 5.25rem 0;
    > div {
      margin-top: 3.75rem;
    }
  }
`
