import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  label?: string
  image?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  cta,
  description,
  label,
  image,
  title,
}: Props) {
  return (
    <Container dial={5} row tag="section" stretch>
      <FadeInOutAnimation>
        <ImageWrap>
          {image ? (
            <LazyLoad once>
              <BackgroundImage style={{ backgroundImage: `url(${image})` }} />
              <Image style={{ backgroundImage: `url(${image})` }} />
            </LazyLoad>
          ) : null}
        </ImageWrap>
      </FadeInOutAnimation>
      <TextWrap dial={4}>
        {label ? (
          <FadeInOutAnimation>
            <Label>{label}</Label>
          </FadeInOutAnimation>
        ) : null}
        {title ? (
          <FadeInOutAnimation>
            <Title>{title}</Title>
          </FadeInOutAnimation>
        ) : null}
        {description ? (
          <FadeInOutAnimation>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInOutAnimation>
        ) : null}
        {cta
          ? cta.map((item, index) => (
              <FadeInOutAnimation key={index}>
                <CTA {...item} />
              </FadeInOutAnimation>
            ))
          : null}
      </TextWrap>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  margin: 9.75rem auto;
  position: relative;
  > div {
    width: 50%;
  }

  @media (max-width: 1199px) {
    margin: 5.25rem auto;
  }

  @media (max-width: 767px) {
    display: block;
    > div {
      width: 100%;
    }
  }
`

const ImageWrap = styled.div`
  overflow: hidden;
  position: relative;
  padding: 3.75rem 0 3.75rem 3.75rem;

  @media (max-width: 1199px) {
    padding: 3rem 0 3rem 3rem;
  }

  @media (max-width: 1023px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const BackgroundImage = styled.span`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: -3.75rem;
  right: 11.875rem;
  bottom: -3.75rem;
  left: -3.75rem;

  @media (max-width: 1199px) {
    top: -3rem;
    bottom: -3rem;
    left: -3rem;
  }

  @media (max-width: 1023px) {
    top: -1.5rem;
    bottom: -1.5rem;
    right: calc(50% - 1.5rem);
    left: -1.5rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Image = styled.span`
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 78%;
  position: relative;
  z-index: 2;

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const TextWrap = styled(FlexBox)`
  padding-left: 10.347vw;
  > div {
    width: 100%;
  }

  @media (max-width: 1199px) {
    padding-left: 5.25rem;
  }

  @media (max-width: 1023px) {
    padding-left: 3.75rem;
  }

  @media (max-width: 767px) {
    margin-top: 2.25rem;
    padding: 0 1.5rem;
  }
`

const Label = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 0.9375rem;
  letter-spacing: 1.5px;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    margin-left: 1.5625rem;
  }
`

const Description = styled.div`
  max-width: 25.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  padding-right: 1.5rem;

  @media (max-width: 767px) {
    max-width: none;
    padding-right: 0;
  }
`

const CTA = styled(Button)`
  margin-top: 2.25rem;

  @media (max-width: 1199px) {
    margin-top: 1.5rem;
  }
`
